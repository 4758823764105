<template>
    <div>
        <text-input
          @input="changeAddressText"
          @blur="changeAddressText"
          v-model="displayAddress"
          ref="search"
        />
        <span v-if="! validAddress" class="form-error">Please choose a valid address</span>

    </div>
</template>

<script>
//import GoogleMapsApiLoader from 'google-maps-api-loader';
import TextInput from '@/components/ui/TextInput';

export default {
    components: { TextInput },
    emits: ['addressTextChange'],
    data() {
        return {
            apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
            displayAddress: this.$attrs.value,
            validAddress: true,
        }
    },
    mounted() {
        this.checkAndAttachMapScript(this.initLocationSearch)
    },
    methods: {
        checkAndAttachMapScript(callback) {
            if (window.google) {
                callback();
                return;
            }
            window.mapApiInitialized = callback;
            let script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places,geometry&callback=mapApiInitialized`;
            document.body.appendChild(script);
        },
        changeAddressText() {
            this.validAddress = false
            this.$emit('addressTextChange', this.validAddress)
        },
        initLocationSearch() {
            let autocomplete = new window.google.maps.places.Autocomplete(this.$refs.search.$refs.field);
            let vm = this;
            autocomplete.setFields(['address_component', 'geometry']);
            autocomplete.addListener("place_changed", function() {
                let place = autocomplete.getPlace();
                if (place) {
                    vm.validAddress = true
                    let placeObj = {
                        addresses: place.address_components ?? null,
                        lattitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng(),
                        formattedAddress: vm.$refs.search.$refs.field.value,
                        is_valid: vm.validAddress,
                    };
                    vm.displayAddress = vm.$refs.search.$refs.field.value
                    vm.$emit('addressChange', placeObj);
                }
            });
        }
    }
}
</script>

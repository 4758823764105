<template>
    <div>
        <div class="flex flex-1 h-full flex-col">
            <div class="poi-wrapper mb-4 py-4 pl-4 pr-12" v-for="(point, index) in pointsOfInterest" :key="index" :class="{'bg-gray-100' : index % 2 === 0}">
                <div class="relative">
                    <label class="flex justify-between" for="category">
                        <span>category</span>
                        <modal-link
                                route="websites.points-of-interest.categories"
                                :params="{ id: website.id}"
                            >
                            <span class="text-highlight-textSmall underline pl-4 cursor-pointer flex items-center -mb-1">
                                <Icon name="plus" class="2-3 h-3 mr-1" /> add new
                            </span>
                        </modal-link>
                    </label>
                    <dropdown
                        :options="sortedCategories"
                        :placeholder="'Select a category...'"
                        v-model="point.point_of_interest_category_id"
                        class="mt-1"
                    ></dropdown>
                    <!-- <span class="form-hint"></span> -->
                </div>

                <div>
                    <label :for="`short_name_${index}`">name</label>
                    <text-input :id="`short_name_${index}`" v-model="point.short_name" />
                    <!-- <span class="form-hint"></span> -->
                </div>
                <div>
                    <label :for="`autocomplete${index}`">address</label>
                    <div>
                    <address-auto-complete :ref="`autocomplete${index}`" :id="`autocomplete${index}`" :value="point.address" @addressTextChange="addressChanged(point, $event)" @addressChange="updatePointOfInterestAddress(point,$event)"></address-auto-complete>
                    </div>
                    <!-- <span class="form-hint"></span>                     -->
                </div>
                <div class="close-btn" @click="removePointOfInterest(index)">
                    <icon name="closeCircle" class="h-5 w-5 text-gray-600 cursor-pointer ml-4 mt-4"></icon>
                </div>
            </div>

            <div class="form-row mt-1">
                <loader :loading="loading" :backdrop="true" />
                <div class="text-link mb-2 flex items-center justify-center cursor-pointer"  @click="addNewPointOfInterest()">
                    <icon name="plus" class="h-4 w-4 inline mr-2 cursor-pointer"></icon> add new point of interest
                </div>
            </div>
        </div>
        <modal-footer v-if="changed" :primary="updatePointsOfInterest"></modal-footer>
    </div>
</template>

<script>
    import EventBus from "@/utils/EventBus";
    import Icon from "@/components/ui/Icon";
    import {mapGetters, mapActions} from "vuex";
    import Dropdown from "@/components/ui/Dropdown";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalLink from "@/components/ui/modals/ModalLink";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import AddressAutoComplete from "@/components/ui/AddressAutoComplete";
    import Loader from "@/components/ui/Loader";
    import TextInput from '@/components/ui/TextInput';

    export default {
        components: {
            ModalFooter,
            Icon,
            AddressAutoComplete,
            Dropdown,
            ModalLink,
            Loader,
            TextInput,
        },
        mixins: [ ModalNavigation ],
        data: () => {
            return {
                website: {},
                pointsOfInterest: [],
                pointsOfInterestFromApi: [],
                changedPoints: [],
                categories: [],
                name: '',
                poiAlertId: 'poi-alert',
                isProcessing: false,
                changed: false,
                loading: true
            }
        },
        computed: {
            ...mapGetters({
                getPointsOfInterest: 'points_of_interest/getPointsOfInterest',
                getUnsavedChanges: 'unsaved_changes/getUnsavedChanges',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
            sortedCategories: function() {
                return this.categories.slice().sort((a, b) => a.value.localeCompare(b.value, 'en', { numeric: true }));
            },
        },
        methods: {
            ...mapActions({
                notifySuccess: 'alerts/notifySuccess',
                notifyError: 'alerts/notifyError',
                loadPointsOfInterestInStore: 'points_of_interest/loadPointsOfInterest',
                pushUnsavedChanges: 'unsaved_changes/pushUnsavedChanges',
                clearUnsavedChanges: 'unsaved_changes/reset'
            }),
            addNewPointOfInterest() {
                this.pointsOfInterest.push({});
            },
            addressChanged(point, payload) {
                point.is_valid = payload
            },
            updatePointOfInterestAddress(point, payload) {
                point.lat = payload.lattitude;
                point.lon = payload.longitude;
                point.address = payload.formattedAddress;
                point.is_valid = payload.is_valid;
                point.lon_lat = `${Number(point.lon).toFixed(6)} ${Number(point.lat).toFixed(6)}`;
            },
            removePointOfInterest(index) {
                this.pointsOfInterest.splice(index, 1);
            },
            updatePointsOfInterest() {
                let payloadObj = {};
                let validCategory = true;
                let validName = true;
                let validAddress = true;
                payloadObj.pointsOfInterest = [];
                this.pointsOfInterest.map((point) => {
                    if (! point.point_of_interest_category_id || ! this.categories.indexOf(category => category.key === point.point_of_interest_category_id)) {
                        validCategory = false;
                    }
                    if (!point.short_name || point.short_name.length <= 0) {
                        validName = false;
                    }
                    if (!point.address || point.address.length <= 0 || point.is_valid === false) {
                        validAddress = false;
                    }
                    payloadObj.pointsOfInterest.push({
                        id_point_of_interest: point.id_point_of_interest ?? null,
                        id_community_point_of_interest: point.id_community_point_of_interest ?? null,
                        address: point.address,
                        lon_lat: point.lon_lat,
                        short_name: point.short_name,
                        point_of_interest_category_id: point.point_of_interest_category_id
                    });
                });

                if ( ! validCategory) {
                    this.notifyError('All points of interest must have a category.');
                    return
                }

                if ( ! validName) {
                    this.notifyError('All points of interest must have a name.');
                    return
                }

                if ( ! validAddress) {
                    this.notifyError('All points of interest must have an address.');
                    return
                }

                this.isProcessing = true;
                this.$cmsDataProvider.create('updatePointsOfInterest', { communityId: this.website.communities_id, data: payloadObj })
                    .then((response) => {
                        this.loadPointsOfInterestInStore(response);
                        this.pointsOfInterest = this.getPointsOfInterest;
                        this.pointsOfInterestFromApi = this.getPointsOfInterest;
                        this.isProcessing = false;
                        this.notifySuccess('The points of interest were saved successfully');
                    }).catch(() => {
                        this.isProcessing = false;
                        this.pointsOfInterestFromApi = [];
                        this.notifyError('There was an error saving the information');
                    });
            }          
        },
        async mounted() {
            this.website = this.getWebsiteInfo

            this.$cmsDataProvider.get('pointsOfInterestCategories').then((response) => {
                response.map((category) => {
                    this.categories.push({
                        key: category.id_point_of_interest_category,
                        value: category.point_of_interest_category_name,
                    });
                });
            });

            this.$cmsDataProvider.get('pointsOfInterest', { communityId: this.website.communities_id }).then((response) => {
                this.loading = true;
                this.clearUnsavedChanges();
                this.loadPointsOfInterestInStore(response);
                this.pointsOfInterest = this.getPointsOfInterest;
            }).catch( () => {
                this.notifyError('There was an error fetching the points of interests');
            }).finally( () => {
                let unsaved_changes = this.getUnsavedChanges(this.$route.name);
                // merge data from api with unsaved changes
                this.pointsOfInterest = [ ...this.pointsOfInterest, ...unsaved_changes];
                this.clearUnsavedChanges();
                this.loading = false;
            });

            EventBus.on(`${this.$route.name}-route-leave-hook`, () => {
                let unsavedChanges = this.pointsOfInterest.filter( item => !this.pointsOfInterestFromApi.includes(item));
                this.pushUnsavedChanges( { route: this.$route.name, data: unsavedChanges });
            })
        },
        watch: {
            pointsOfInterest() {
                this.changed = true
            }
        },
    }
</script>

<style scoped>
    label {
        @apply text-black text-sm font-600 font-inter select-none tracking-wide lowercase;
    }
    .poi-wrapper{
        position: relative;
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
        grid-column-gap: 30px;
        grid-row-gap: 15px;
    }
    .close-btn{
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
</style>
